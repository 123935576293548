@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: AvenirNext Regular;
    src: url("./font-meatme/AvenirNext-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

body {
    margin: 0;
    font-family: "AvenirNext Regular" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 375) {
    .facet_sidebar {
        display: none;
    }
}
